<template>
  <b-modal
    :id="modalId"
    ref="add-edit-operator"
    :title="title"
    @ok="handleOk"
    @hidden="resetForm()"
  >
    <b-form-group label="Company Name" label-for="company-input">
      <b-form-input
        id="company-input"
        name="company-input"
        v-model.trim="form.company"
        :state="validateState('company')"
        aria-describedby="company-input-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="company-input-live-feedback">
        Company name is a required field and must be at least 3 characters long.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Operator Name" label-for="name-input">
        <b-form-input
          id="name-input"
          name="name-input"
          v-model.trim="form.name"
          :state="validateState('name')"
          aria-describedby="name-input-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="name-input-live-feedback">
          This is a required field and must be at least 3 characters long.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="E-mail" label-for="email-input">
        <b-form-input
          id="email-input"
          name="email-input"
          type="email"
          v-model.trim="form.email"
          :state="validateState('email')"
          aria-describedby="email-input-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="email-input-live-feedback">
          Valid E-mail is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Mobile Number" label-for="phone-input">
        <b-form-input
          id="phone-input"
          name="phone-input"
          type="tel"
          v-model.trim="form.phoneNo"
          :state="validateState('phoneNo')"
          aria-describedby="phone-input-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="phone-input-live-feedback">
          Valid Mobile number is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Zone" label-for="zone-select">
        <b-form-select
          v-model="form.zoneId"
          id="zone-select"
          name="zone-select"
          :state="validateState('zoneId')"
          aria-describedby="zone-select-live-feedback"
          :disabled="operatorId !== null"
        >
          <b-form-select-option :value="null">
            Select a Zone
          </b-form-select-option>
          <template v-for="entry in zone">
            <b-form-select-option :key="entry.id" :value="entry.id">
              {{ entry.name }}
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback id="zone-select-live-feedback">
          Please select a zone.
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { GET_ZONES, GET_OPERATOR_BY_ID } from "@/graphql/queries/queries";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

export default {
  name: "addEditOperator",
  props: {
    operatorId: {
      type: String,
      default: null,
    },
    modalId: String,
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        company: null,
        zoneId: null,
        phoneNo: null,
      },
      operators_by_pk: null,
    };
  },

  computed: {
    title() {
      return this.operatorId ? "Edit Operator" : "Add New Operator";
    },
  },

  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      company: {
        required,
        minLength: minLength(3),
      },
      zoneId: {
        required,
      },
      phoneNo: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        console.log(this.$v.form.$anyError);
        return;
      }
      if (this.operatorId) {
        console.log("update operator");
        this.$parent.$parent.updateOperator(this.operatorId, this.form);
      } else {
        console.log("add operator");
        this.$parent.$parent.addOperator(this.form);
      }

      this.$nextTick(() => {
        this.$bvModal.hide(this.modalId);
      });
    },

    fetchOperator() {
      console.log("featchOperator: ", this.operatorId);
      if (this.operatorId) {
        this.$apollo.queries.operators_by_pk.skip = false;
        this.$apollo.queries.operators_by_pk.refetch();
      } else {
        this.$apollo.queries.operators_by_pk.skip = true;
      }
    },

    handleOk(bvModealEvt) {
      bvModealEvt.preventDefault();
      this.handleSubmit();
    },

    resetForm() {
      console.log("reseting form data");

      this.form = {
        name: null,
        email: null,
        company: null,
        zoneId: null,
        phoneNo: null,
      };
      this.operators_by_pk = null;

      this.$nextTick(() => {
        this.$v.$reset();
        this.$parent.resetProp();
      });
    },
  },

  mounted() {
    this.fetchOperator();
  },

  watch: {
    operatorId() {
      console.log("changed operator id", this.operatorId);
      this.fetchOperator();
    },

    operators_by_pk() {
      if (this.operators_by_pk) {
        this.form.name = this.operators_by_pk.user.name;
        this.form.email = this.operators_by_pk.user.email;
        this.form.company = this.operators_by_pk.company_name;
        this.form.zoneId = this.operators_by_pk.zone_id;
        this.form.phoneNo = this.operators_by_pk.user.phone_no;
      }
    },
  },

  apollo: {
    zone: {
      query: GET_ZONES,
      error: console.error,
    },

    operators_by_pk: {
      query: GET_OPERATOR_BY_ID,
      variables() {
        return {
          id: this.operatorId,
        };
      },
      skip: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
