<template>
  <div class="sub-operator-list row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">All Operators</h4>
          <b-button
            @click="openAddModal"
            variant="success"
            size="sm"
            class="ml-1 btn-add-operator"
            v-if="['webadmin', 'zoneadmin'].includes(this.currentRole)"
          >
            <span>Add an Operator</span>
          </b-button>
          <p class="text-muted font-13 mb-4"></p>
          <!-- Filter element -->
          <b-form-select
            v-model="selectedZone"
            class="col-sm-3 mb-3 mr-2"
            v-if="['webadmin'].includes(this.currentRole)"
          >
            <b-form-select-option :value="null"
              >Filter by Zone</b-form-select-option
            >
            <template v-for="item in zoneTable">
              <b-form-select-option :key="item.id" :value="item.id">
                {{ item.name }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <!-- Table -->
          <div class="table-resonsive mb-0">
            <b-table
              :items="operatorsTable"
              :fields="fields"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template v-slot:cell(avatar)="row">
                <img
                  :src="getImageUrl(row.item.user.avatar)"
                  :alt="row.item.user.name"
                />
              </template>

              <template v-slot:cell(company_name)="row">
                {{ row.value }}
                <span> {{ row.item.zone.name }} </span>
              </template>

              <template v-slot:cell(user)="row">
                {{ row.value.name }}
              </template>

              <template v-slot:cell(pilots)="row">
                {{ calculateFlights(row.item.pilots) }}
              </template>

              <template v-slot:cell(actions)="row">
                <a title="View" class="action-icon view">
                  <i class="mdi mdi-eye"></i>
                </a>
                <a
                  @click="openEditModal(row.item, row.index, $event.target)"
                  title="Edit"
                  class="action-icon edit"
                >
                  <i class="mdi mdi-square-edit-outline"></i>
                </a>
              </template>

              <template v-slot:row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </div>
          <div class="row">
            <!-- # of entries per page selector -->
            <div class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="ml-2 mr-2"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="dataTables_paginate paging_simple_numbers">
                <b-pagination
                  v-model="currentPage"
                  class="justify-content-center justify-content-sm-end"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
    <!-- Add / Edit operator Modal -->
    <add-edit-operator-modal
      ref="addEditOperatorModalRef"
      :modalId="addEditModalId"
      :operatorId="selectedOperatorId"
    />
  </div>
  <!-- end row -->
</template>

<script>
import addEditOperatorModal from "@/components/subcomponents/operator/addEditOperatorModal.vue";
import { GET_ZONES } from "@/graphql/queries/queries";
import { mapState } from "vuex";
const ZONES_ATTRIBUTE = "zone";

export default {
  name: "operatorListing",
  props: {
    operators: {
      type: Array,
    },
  },

  components: {
    addEditOperatorModal,
  },

  data() {
    return {
      selectedZone: null,
      zoneTable: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      operatorsTable: [],
      fields: [
        { key: "avatar", label: "Avatar", class: "avatar" },
        {
          key: "company_name",
          tdClass: "company",
          label: "Company",
          sortable: true,
        },
        {
          key: "user",
          label: "Contact Person",
          class: "operator-name",
          sortable: true,
        },
        { key: "pilots.length", label: "No of Pilots", sortable: true },
        {
          key: "pilots",
          label: "No of flights",
          sortable: true,
          // sorting using a custom function
          sortByFormatted: true,
          formatter: (value) => {
            return value.reduce(
              (total, val) =>
                total + val.cpy_stats_aggregate.aggregate.sum.total_flights,
              0
            );
          },
        },
        { key: "actions", label: "Actions" },
      ],

      // modal for add/edit operator
      addEditModalId: "add-edit-operator",
      selectedOperatorId: null,
    };
  },

  computed: {
    ...mapState(["currentRole"]),
    rows() {
      return this.operators.length;
    },
  },
  watch: {
    operators() {
      this.operatorsTable = this.operators;
    },
    selectedZone() {
      this.operatorsTable = this.operators;
      if (this.selectedZone) {
        this.operatorsTable = this.operators.filter(
          (a) => a.zone.id === this.selectedZone
        );
      }
    },
  },
  mounted() {
    this.operatorsTable = this.operators;
  },
  methods: {
    getImageUrl(url) {
      return url || "/img/default-avatar.jpg";
    },
    openEditModal(rowItem, rowIndex, eventTarget) {
      this.selectedOperatorId = null;
      this.selectedOperatorId = rowItem.operator_id;
      this.$root.$emit("bv::show::modal", this.addEditModalId, eventTarget);
    },

    openAddModal(e) {
      this.selectedOperatorId = null;
      this.$root.$emit("bv::show::modal", this.addEditModalId, e.target);
    },

    resetProp() {
      this.selectedOperatorId = null;
    },

    calculateFlights(value) {
      return value.reduce(
        (total, val) =>
          total + val.cpy_stats_aggregate.aggregate.sum.total_flights,
        0
      );
    },
  },
  apollo: {
    zoneTable: {
      // graphql query
      query: GET_ZONES,
      update(data) {
        this.dataReady = true;
        return data[ZONES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-operator-list {
  table.table {
    color: #6c757d;
  }

  td.operator-name {
    font-weight: 600;
  }

  td.company span,
  td.operator-name span {
    display: block;
    font-size: 13px;
    color: #98a6ad;
    font-weight: 400;
  }

  .avatar img {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    border: #fff 2px solid;
    box-shadow: #000 0px 0px 2px;
  }

  .action-icon {
    display: inherit;
    padding: 0 5px;
    font-size: 1.2rem;
    color: #98a6ad;
    cursor: pointer;
  }

  .action-icon:hover {
    color: #6c757d;
  }

  h4.header-title {
    display: inline-block;
    vertical-align: sub;
    margin-right: 30px;
  }
}
</style>
