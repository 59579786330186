<template>
  <div class="template-operator-list">
    <div>
      <operator-listing :operators="operators" />
    </div>
  </div>
</template>

<script>
import operatorListing from "@/components/subcomponents/operator/operatorListing.vue";
import {
  LIST_OPERATORS,
  GET_USER_BY_EMAIL_ROLE,
} from "@/graphql/queries/queries";
import {
  ADD_USER,
  ADD_USER_ROLE,
  ADD_SINGLE_OPERATOR,
  UPDATE_SINGLE_OPERATOR,
} from "@/graphql/mutations/mutations";

export default {
  name: "operator",

  components: {
    operatorListing,
  },

  data() {
    return {
      operators: [],
    };
  },

  methods: {
    async addOperator(formData) {
      const user = {
        name: formData.name,
        email: formData.email,
        phone_no: formData.phoneNo,
      };
      try {
        // check user exists with email and operator role.
        const userbyEmailRoleQuery = await this.$apollo.query({
          query: GET_USER_BY_EMAIL_ROLE,
          variables: {
            email: user.email,
            role: "operator",
          },
        });
        if (userbyEmailRoleQuery) {
          if (userbyEmailRoleQuery.data.users.length > 0) {
            console.error("operator with email already exists");
            return;
          }
          // first try to insert user. in case of conflict retrieve userid and roles.
          const addUserMutation = await this.$apollo.mutate({
            mutation: ADD_USER,
            variables: {
              input: user,
            },
          });
          if (addUserMutation) {
            const res = addUserMutation.data.insert_users_one;
            const userID = res.id;
            const roles = res.user_roles;
            // check if this user is already an operator
            if (this._.find(roles, ["role", "operator"])) {
              console.error(`operator by email ${user.email} already exists`);
              return;
            } else {
              // add operator
              const operator = {
                company_name: formData.company,
                zone_id: formData.zoneId,
                operator_id: userID,
              };

              const addSingleOperator = await this.$apollo.mutate({
                mutation: ADD_SINGLE_OPERATOR,
                variables: {
                  input: operator,
                },
              });
              if (addSingleOperator) {
                // add operator role
                const userRole = {
                  user_id: userID,
                  role: "operator",
                };
                const addUserRole = await this.$apollo.mutate({
                  mutation: ADD_USER_ROLE,
                  variables: {
                    input: userRole,
                  },
                });
                if (addUserRole) {
                  this.$apollo.queries.operators.refetch();
                }
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async updateOperator(pk, formData) {
      const user = {
        name: formData.name,
        email: formData.email,
        phone_no: formData.phoneNo,
      };

      const operator = {
        company_name: formData.company,
      };

      try {
        const updateSingleOperator = await this.$apollo.mutate({
          mutation: UPDATE_SINGLE_OPERATOR,
          variables: {
            pk,
            operator,
            user,
          },
        });
        if (updateSingleOperator) {
          console.log("response", updateSingleOperator);
          // refetch all operators
          this.$apollo.queries.operators.refetch();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },

  apollo: {
    operators: {
      query: LIST_OPERATORS,
      error: console.error,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../css/sass_files";

.template-operator-list {
  padding-top: 24px;
}
</style>
